table {
    border: 1px solid black;
    width: 100%;
    max-width: 100vw;
    margin:0;
    padding:0;
    border-collapse: collapse;
    border-spacing: 0;
    
  }

  table tr {
    border: 1px solid #ddd;
    /* padding: 5px; */
		background: #fff;

  }

  table th, table td {
    padding: 10px;
    text-align: center;

  }

  table th {
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  
  @media screen and (max-width: 840px) {
   .desc {
    text-align: right !important;
   }
   #card table {
      border: 0;
    }
    #card table tbody {
        border: 0;
    }
   #card table thead {
      display: none;
    }

   #card table tr {
      margin-bottom: 20px;
      display: block;

	 box-shadow: 2px 2px 1px #dadada;

    }

   #card table td {
      display: block;
      text-align: right;
      overflow: hidden;
      font-size: 13px;
    }

    #card table .company-name {
        padding: 20px;
        display: block;
        text-align: center;
        overflow: hidden;
        font-size: 20px;
        font-weight: 700;
      }
   #card table td:last-child {
      border-bottom: 0;
    }

   #card table td::before {
      content: attr(data-label);
      float: left;
      text-transform: uppercase;
      font-weight: 900;

    }
    #card table .company-name::before {
        content: none;
        float: left;
        text-transform: uppercase;
        font-weight: 900;
  
      }
    	#card	tbody{
			line-height:1em!important;
		}

  }
 