#landing {
  /* background-image: url(./BullisBlue.png); */
  /* padding: 20% 0; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 90vh;
  background: linear-gradient(90deg, rgba(59,42,255,1) 0%, rgba(194,0,255,1) 100%);
}
.genPage {
  /* backgrou
  nd-image: url(./BullisBlue.png); */
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px);
  background: rgb(59,42,255);
  background: linear-gradient(90deg, rgba(59,42,255,1) 0%, rgba(194,0,255,1) 100%);
}
.general-page-clear {
  /* backgrou
  nd-image: url(./BullisBlue.png); */
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 60px);

}
.gradient-text {
  background-image: linear-gradient(90deg, rgba(59,42,255,1) 18%, rgba(194,0,255,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
#landing h1 {
  font-size: 2em;
}
.our-goal h2 {
  font-size: 5em;
}
.our-goal h3 {
  font-size: 2em;
}
.prog-container {
  position: absolute;
}
.circle-stat {
  rotate: 270deg;
  transform: scaleY(-1);
}
.circle-text {
  position: absolute;
  top: 50%;  /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */

    transform: translate(-50%, -50%);
    font-size: 3.5em !important;
}
.circle-back {
  fill: white;
  stroke: rgba(107, 107, 107, 0.7);
}
.circle-prog {
 fill: none;
}

/* @media (max-width: 978px) { 
  #landing {
    padding: 12em 0;
  }
  .genPage {
    padding: 8em 1em;
  
  }
  .header-text {
    font-size: 4rem;
    color: white !important;
    font-weight: 700;
    text-align: center;
  
  }
} */
#navbar {
  z-index: 100;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;

}
.header-text {
  font-size: 5rem;
  color: white !important;
  font-weight: 700;
  text-align: center;

}
.primBlurple {
  color: white !important;
  color: #3b2aff !important;
}
.primBlack {
  color: white !important;
}
.prim-black {
  color: black !important;
  font-weight: 700;
}
.subheader-text {
  font-size: 2rem;
  font-weight: 700;
}
.header2-text {
  font-size: 1rem;
  font-weight: 700;
}
.centered {
  text-align: center;
}
.joblisting {
  border: black 1px solid;
  border-bottom: black 0 solid;
}
a {
  text-decoration: none;
  color: inherit;
}
.jobs {
  margin-top: 1rem;
}
.scroll-arrow {
  position: relative;
  top: 300px;
  font-size: 2em;
}
.footer {
  
}

@media (max-width: 978px) {  
  #landing h1 {
    font-size: 1em;
  }
  
.footer {
  display: none;
}
.bot-container {
  height: fit-content !important;
}
.bot {
  display: block;
  width: 100%!important;
  height: 1000px!important;
}
}
@media (max-width: 350px) {
  #landing h1,h2 {
    font-size: 0.5em;
  }
}
.link-btn {
  background: transparent;
  margin: 0;
  padding: 0;
  border: none;
  color: #0000EE;
  /* text-decoration: underline; */
}