@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@200;400;500;700;900&display=swap');
body {
  margin: 0;
  /* background: rgb(251, 239, 215); */
  font-family: 'Roboto';
  font-weight: 200;
  width: 100%;
  /* overflow-x: hidden; */
  height: calc(100%)
}
html {
  height: calc(100%);
  scroll-behavior: smooth;
}
element::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
} 


.filter-label {
  border: #999 solid 1px;
  /* background:; */
  font-size: 2em;

}