.search-bar {
    margin-top: 1em;
    background: white;

    border-radius: 20px;
    width: 310px;
    -webkit-transition: width 0.4s ease-in-out;
    transition: width 0.4s ease-in-out;
    padding: 0.3rem 0.6rem;
}
.search-bar:focus {
    width: 310px !important;
}